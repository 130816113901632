import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { semaphore } from "../semaphore";
import { FacadeAPI } from "../../rest/RestClient";
import { checkAndRefreshToken, REFRESH_DONE, REFRESH_FAIL } from "./AuthSlice";

export const getNonSharedBeneficiaryUnits = createAsyncThunk(
  "planSlice/getNonSharedBeneficiaryUnits",
  async (accountCode, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("planSlice/getBeneficiaryUnits"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET(
        "/account/" + accountCode + "/plan/beneficiaryUnits?show=NON_SHARED",
        null,
        { noTimeout: true }
      );

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getSharedBucketsDetails = createAsyncThunk(
  "planSlice/getSharedBucketsDetails",
  async (accountCode, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("planSlice/getSharedBucketsDetails"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET(
        "/account/" + accountCode + "/plan/sharedBucketsDetails",
        null,
        { noTimeout: true }
      );

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAdditionalSrv = createAsyncThunk(
  "planSlice/getAdditionalSrv",
  async (accountCode, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("planSlice/getAdditionalSrv"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET("/account/" + accountCode + "/plan/additionalServices");

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getTravelPlan = createAsyncThunk(
  "planSlice/getTravelPlan",
  async (accountCode, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("planSlice/getTravelPlan"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET("/account/" + accountCode + "/plan/travelPlan");

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getTravelPacksServiceList = createAsyncThunk(
  "planSlice/getTravelPacksServiceList",
  async (accountCode, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("planSlice/getTravelPacksServiceList"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET(
        "/account/" + accountCode + "/plan/travelPacksServiceList"
      );

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getTravelPlanConfig = createAsyncThunk(
  "planSlice/getTravelPlanConfig",
  async (accountCode, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("planSlice/getTravelPlanConfig"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET("/account/" + accountCode + "/plan/travelPlanConfig");

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const cancelSPC = createAsyncThunk(
  "planSlice/cancelSPC",
  async ({ accountCode, processId }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("planSlice/cancelSPC"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      await FacadeAPI.PUT(
        "/account/" + accountCode + "/plan/cancelSPC?processId=" + processId,
        null,
        {
          expectedResponse: "none",
        }
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateAccountProduct = createAsyncThunk(
  "planSlice/updateAccountProduct",
  async ({ accountCode, product }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("planSlice/updateAccountProduct"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.PUT(
        "/account/" + accountCode + "/plan/updateAccountProduct",
        {
          product,
        },
        { expectedResponse: "none" }
      );

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getConfiguration = createAsyncThunk(
  "planSlice/getConfiguration",
  async (_args, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("planSlice/getConfiguration"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET("/configuration/global");

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getHasUsageRelatedServices = createAsyncThunk(
  "planSlice/getHasUsageRelatedServices",
  async (accountCode, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("planSlice/getHasUsageRelatedServices"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET(
        "/account/" + accountCode + "/plan/hasUsageRelatedServices"
      );

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getSharedPlans = createAsyncThunk(
  "planSlice/getSharedPlans",
  async (accountCode, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("planSlice/getSharedPlans"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET(
        "/account/" + accountCode + "/plan/hierarchySharedBeneficiaryUnits",
        null,
        { noTimeout: true }
      );

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBucketsDetails = createAsyncThunk(
  "planSlice/getBucketsDetails",
  async (accountCode, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("planSlice/getBucketsDetails"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET(
        "/account/" + accountCode + "/plan/bucketsDetails",
        null,
        { noTimeout: true }
      );

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAccountsSharedUsageDetails = createAsyncThunk(
  "planSlice/getAccountsSharedUsageDetails",
  async ({ accountCode, actualUnitCode, bduType, overage }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("planSlice/getAccountsSharedUsageDetails"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET(
        `/account/${accountCode}/plan/accountsSharedUsageDetails`,
        { actualUnitCode, bduType, overage },
        { timeout: 60000 }
      );

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const loadZeroUsageHiddenBdusList = createAsyncThunk(
  "planSlice/loadZeroUsageHiddenBdusList",
  async (_args, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("planSlice/loadZeroUsageHiddenBdusList"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET("/configuration/hiddenBdusList");

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const loadAlwaysDisplayOverageBdus = createAsyncThunk(
  "planSlice/loadAlwaysDisplayOverageBdus",
  async (_args, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("planSlice/loadAlwaysDisplayOverageBdus"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET("/configuration/alwaysDisplayOverageBdus");

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const planSlice = createSlice({
  name: "plan",
  initialState: {
    planLoading: null,
    planError: null,

    nonSharedBeneficiaryUnits: null,
    sharedBucketsDetails: null,
    additionalSrv: null,
    configuration: null,
    usageRelatedServices: null,
    sharedPlans: null,
    bucketsDetails: null,
    accountsSharedUsageDetails: [],
    selectedSharedPlan: null,
    travelPlan: null,
    travelPacksServiceList: null,
    travelPlanConfig: null,
    hiddenBdus: [],
    alwaysDisplayOverageBdus: [],

    non_shared_beneficiary_unit_status: null,
    shared_buckets_details_status: null,
    additional_srv_status: null,
    update_account_product_status: null,
    configuration_status: null,
    usage_related_services: null,
    shared_plans_status: null,
    buckets_details_status: null,
    shared_usage_details_status: null,
    travel_plan_status: null,
    travel_packs_service_list_status: null,
    travel_plan_config_status: null,
    cancel_SPC_status: null,
    hidden_bdus_status: null,
    always_display_bdus_status: null,
  },

  reducers: {
    setPlanError: (state, action) => {
      state.planError = action.payload;
    },
    setNonSharedBeneficiaryUnitStatus: (state, action) => {
      state.non_shared_beneficiary_unit_status = action.payload;
    },
    setSharedBucketsDetailsStatus: (state, action) => {
      state.shared_buckets_details_status = action.payload;
    },
    setAdditionalSrv: (state, action) => {
      state.additionalSrv = action.payload;
    },
    setTravelPlanStatus: (state, action) => {
      state.travel_plan_status = action.payload;
    },
    setCancelSPCStatus: (state, action) => {
      state.cancel_SPC_status = action.payload;
    },
    setAdditionalSrvStatus: (state, action) => {
      state.additional_srv_status = action.payload;
    },
    setUpdateAccountProductStatus: (state, action) => {
      state.update_account_product_status = action.payload;
    },
    setConfigurationStatus: (state, action) => {
      state.configuration_status = action.payload;
    },
    setUsageRelatedServices: (state, action) => {
      state.usage_related_services = action.payload;
    },
    setSelectedSharedPlan: (state, action) => {
      state.selectedSharedPlan = action.payload;
    },
  },

  extraReducers: {
    [getNonSharedBeneficiaryUnits.pending]: state => {
      state.planLoading = true;
      state.planError = null;
      state.non_shared_beneficiary_unit_status = "loading";
    },
    [getNonSharedBeneficiaryUnits.fulfilled]: (state, action) => {
      state.planLoading = false;
      state.nonSharedBeneficiaryUnits = action.payload;
      state.non_shared_beneficiary_unit_status = "success";
    },
    [getNonSharedBeneficiaryUnits.rejected]: (state, action) => {
      state.planLoading = false;
      state.planError = action.payload;
      state.non_shared_beneficiary_unit_status = "failed";
    },

    [getSharedBucketsDetails.pending]: state => {
      state.planLoading = true;
      state.planError = null;
      state.shared_buckets_details_status = "loading";
    },
    [getSharedBucketsDetails.fulfilled]: (state, action) => {
      state.planLoading = false;
      state.sharedBucketsDetails = action.payload;
      state.shared_buckets_details_status = "success";
    },
    [getSharedBucketsDetails.rejected]: (state, action) => {
      state.planLoading = false;
      state.planError = action.payload;
      state.shared_buckets_details_status = "failed";
    },

    [getAdditionalSrv.pending]: state => {
      state.planLoading = true;
      state.planError = null;
      state.additional_srv_status = "loading";
    },
    [getAdditionalSrv.fulfilled]: (state, action) => {
      state.planLoading = false;
      state.additionalSrv = action.payload;
      state.additional_srv_status = "success";
    },
    [getAdditionalSrv.rejected]: (state, action) => {
      state.planLoading = false;
      state.planError = action.payload;
      state.additional_srv_status = "failed";
    },

    [getTravelPlan.pending]: state => {
      state.planLoading = true;
      state.planError = null;
      state.travel_plan_status = "loading";
    },
    [getTravelPlan.fulfilled]: (state, action) => {
      state.planLoading = false;
      state.travelPlan = action.payload;
      state.travel_plan_status = "success";
    },
    [getTravelPlan.rejected]: (state, action) => {
      state.planLoading = false;
      state.planError = action.payload;
      state.travel_plan_status = "failed";
    },

    [getTravelPacksServiceList.pending]: state => {
      state.planLoading = true;
      state.planError = null;
      state.travel_packs_service_list_status = "loading";
    },
    [getTravelPacksServiceList.fulfilled]: (state, action) => {
      state.planLoading = false;
      state.travelPacksServiceList = action.payload.serviceList;
      state.travel_packs_service_list_status = "success";
    },
    [getTravelPacksServiceList.rejected]: (state, action) => {
      state.planLoading = false;
      state.planError = action.payload;
      state.travel_packs_service_list_status = "failed";
    },

    [getTravelPlanConfig.pending]: state => {
      state.planLoading = true;
      state.planError = null;
      state.travel_plan_config_status = "loading";
    },
    [getTravelPlanConfig.fulfilled]: (state, action) => {
      state.planLoading = false;
      state.travelPlanConfig = action.payload;
      state.travel_plan_config_status = "success";
    },
    [getTravelPlanConfig.rejected]: (state, action) => {
      state.planLoading = false;
      state.planError = action.payload;
      state.travel_plan_config_status = "failed";
    },

    [loadZeroUsageHiddenBdusList.pending]: state => {
      state.planLoading = true;
      state.planError = null;
      state.hidden_bdus_status = "loading";
    },
    [loadZeroUsageHiddenBdusList.fulfilled]: (state, action) => {
      state.planLoading = false;
      state.hiddenBdus = action.payload;
      state.hidden_bdus_status = "success";
    },
    [loadZeroUsageHiddenBdusList.rejected]: (state, action) => {
      state.planLoading = false;
      state.planError = action.payload;
      state.hidden_bdus_status = "failed";
    },

    [loadAlwaysDisplayOverageBdus.pending]: state => {
      state.planError = null;
      state.always_display_bdus_status = "loading";
    },
    [loadAlwaysDisplayOverageBdus.fulfilled]: (state, action) => {
      state.alwaysDisplayOverageBdus = action.payload;
      state.always_display_bdus_status = "success";
    },
    [loadAlwaysDisplayOverageBdus.rejected]: (state, action) => {
      state.planError = action.payload;
      state.always_display_bdus_status = "failed";
    },

    [cancelSPC.pending]: state => {
      state.planLoading = true;
      state.planError = null;
      state.cancel_SPC_status = "loading";
    },
    [cancelSPC.fulfilled]: state => {
      state.planLoading = false;
      state.cancel_SPC_status = "success";
    },
    [cancelSPC.rejected]: (state, action) => {
      state.planLoading = false;
      state.planError = action.payload;
      state.cancel_SPC_status = "failed";
    },

    [updateAccountProduct.pending]: state => {
      state.planLoading = true;
      state.planError = null;
      state.update_account_product_status = "loading";
    },
    [updateAccountProduct.fulfilled]: state => {
      state.planLoading = false;
      state.update_account_product_status = "success";
    },
    [updateAccountProduct.rejected]: (state, action) => {
      state.planLoading = false;
      state.planError = action.payload;
      state.update_account_product_status = "failed";
    },

    [getConfiguration.pending]: state => {
      state.planLoading = true;
      state.planError = null;
      state.configuration_status = "loading";
    },
    [getConfiguration.fulfilled]: (state, action) => {
      state.planLoading = false;
      state.configuration = action.payload;
      state.configuration_status = "success";
    },
    [getConfiguration.rejected]: (state, action) => {
      state.planLoading = false;
      state.planError = action.payload;
      state.configuration_status = "failed";
    },

    [getHasUsageRelatedServices.pending]: state => {
      state.planLoading = true;
      state.planError = null;
      state.usage_related_services = "loading";
    },
    [getHasUsageRelatedServices.fulfilled]: (state, action) => {
      state.planLoading = false;
      state.usageRelatedServices = action.payload;
      state.usage_related_services = "success";
    },
    [getHasUsageRelatedServices.rejected]: (state, action) => {
      state.planLoading = false;
      state.planError = action.payload;
      state.usage_related_services = "failed";
    },

    [getSharedPlans.pending]: state => {
      state.userLoading = true;
      state.planError = null;
      state.shared_plans_status = "loading";
    },
    [getSharedPlans.fulfilled]: (state, action) => {
      state.userLoading = false;
      state.sharedPlans = action.payload;
      state.shared_plans_status = "success";
    },
    [getSharedPlans.rejected]: (state, action) => {
      state.userLoading = false;
      state.planError = action.payload;
      state.shared_plans_status = "failed";
    },

    [getBucketsDetails.pending]: state => {
      state.planLoading = true;
      state.planError = null;
      state.buckets_details_status = "loading";
    },
    [getBucketsDetails.fulfilled]: (state, action) => {
      state.planLoading = false;
      state.bucketsDetails = action.payload.bucketsDetails;
      state.buckets_details_status = "success";
    },
    [getBucketsDetails.rejected]: (state, action) => {
      state.planLoading = false;
      state.planError = action.payload;
      state.buckets_details_status = "failed";
    },

    [getAccountsSharedUsageDetails.pending]: state => {
      state.planLoading = true;
      state.planError = null;
      state.shared_usage_details_status = "loading";
    },
    [getAccountsSharedUsageDetails.fulfilled]: (state, action) => {
      state.planLoading = false;
      state.accountsSharedUsageDetails = action.payload.accountsSharedUsageDetails;
      state.shared_usage_details_status = "success";
    },
    [getAccountsSharedUsageDetails.rejected]: (state, action) => {
      state.planLoading = false;
      state.planError = action.payload;
      state.shared_usage_details_status = "failed";
    },
  },
});

export const {
  setPlanError,
  setBeneficiaryUnitStatus,
  setUpdateAccountProductStatus,
  setAdditionalSrv,
  setTravelPlanStatus,
  setCancelSPCStatus,
  setAdditionalSrvStatus,
  setConfigurationStatus,
  setSelectedSharedPlan,
  setNonSharedBeneficiaryUnitStatus,
} = planSlice.actions;

export default planSlice.reducer;
